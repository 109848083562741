<template>
  <div>
    <Card v-bind:bordered="false" v-for="item in articles" :key="item.id">
      <div class="header">
        <a slot="title" v-bind:href="'/article.html?id='+item.id"> {{ item.title }}</a>
        <span style="float: right" class="publish-time">
              {{ format(new Date(item.publishTime),"YYYY-MM-DD HH:mm:ss") }}
          </span>
      </div>

      <div>
        <a v-bind:href="'/article.html?id='+item.id">
          <div class="show-image" :style="{'background':'url('+item.imageUrl+') no-repeat'}"></div>
        </a>
      </div>
      <div>
        <p>{{ item.desc }}</p>
      </div>
      <div class="footer" style="vertical-align: center;padding-bottom: 10px">
        <div>
          <a class=" button" type="button" v-bind:href="'/article.html?id='+item.id">查看更多...</a>
        </div>

        <span style="float: right">
            阅读：{{ item.readCount }} | 点赞：{{ item.starCount }} | 评论：{{ item.commentCount }}
          </span>
      </div>
    </Card>
    <div id="pageGroup">
      <button id="pre-btn" type="button" @click="prevPage" :disabled="pageNo === 1">上一页</button>
      <button id="next-btn" type="button" @click="nextPage" :disabled="totalPage === pageNo">下一页</button>
    </div>
  </div>

</template>

<script>

import {queryArticles} from '@/api/api';
import moment from 'moment';


export default {
  name: "MainArticles",
  data: function () {
    return {
      articles: [],
      pageNo: 1,
      pageSize: 5,
      totalPage: 1
    }
  },
  mounted: function () {
    this.invokeQuery()
  },
  computed: {},
  methods: {
    invokeQuery() {
      let that = this;
      queryArticles({pageNo: that.pageNo, pageSize: that.pageSize}).then(response => {
        this.articles = response.biz.rows;
        this.pageNo = response.biz.pageNo;
        this.totalPage = response.biz.totalPage;
      })
    },
    nextPage() {
      this.pageNo++;
      this.invokeQuery();
    },
    prevPage() {
      this.pageNo--;
      this.invokeQuery();
    },
    format(date,fmt){
     return  moment(date).format(fmt);
    }
  }
}
</script>

<style scoped>
#main {
  -moz-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -ms-flex: 1;
  width: 70%;
  float: right;
  padding: 20px 0;
}

.ivu-card {
  margin-bottom: 30px;
}

.header {
  line-height: 50px;
  border-bottom: #aaaaaa 1px solid;
}

.show-image{
  width:100%;
  height: 200px;
  overflow: hidden;
  background-size: cover !important;
}


.header a {
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  color: #383636;
}

.publish-time {
  color: #3c3b3b;
  display: block;
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 0.7em;
  font-weight: 800;
  letter-spacing: 0.25em;
  margin-top: 0.5em;
  text-transform: uppercase;
  white-space: nowrap;
  float: right;
}

.footer {
  text-align: center;
  padding: 10px 0 5px 0;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  -ms-transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease, color 0.2s ease;
  background-color: transparent;
  border: 0;
  box-shadow: inset 0 0 0 1px rgba(160, 160, 160, 0.3);
  color: #3c3b3b !important;
  cursor: pointer;
  display: inline-block;
  font-family: "Raleway", Helvetica, sans-serif;
  font-size: 0.6em;
  font-weight: 800;
  height: 3.8125em;
  width: 11em;
  letter-spacing: 0.25em;
  line-height: 3.8125em;
  padding: 0 0.5em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
  box-shadow: inset 0 0 0 1px #2ebaae;
  color: #2ebaae !important;
}

input[type="submit"]:hover:active,
input[type="reset"]:hover:active,
input[type="button"]:hover:active,
button:hover:active,
.button:hover:active {
  background-color: rgba(46, 186, 174, 0.05);
}

input[type="submit"]:before, input[type="submit"]:after,
input[type="reset"]:before,
input[type="reset"]:after,
input[type="button"]:before,
input[type="button"]:after,
button:before,
button:after,
.button:before,
.button:after {
  color: #aaaaaa;
  position: relative;
}

input[type="submit"]:before,
input[type="reset"]:before,
input[type="button"]:before,
button:before,
.button:before {
  left: -1em;
  padding: 0 0 0 0.75em;
}

input[type="submit"]:after,
input[type="reset"]:after,
input[type="button"]:after,
button:after,
.button:after {
  left: 1em;
  padding: 0 0.75em 0 0;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  display: block;
  margin: 0 0 1em 0;
  width: 100%;
}

input[type="submit"].big,
input[type="reset"].big,
input[type="button"].big,
button.big,
.button.big {
  font-size: 0.7em;
  padding: 0 3em;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.5em;
}

input[type="submit"].disabled, input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  color: rgba(160, 160, 160, 0.3) !important;
}

input[type="submit"].disabled:before, input[type="submit"]:disabled:before,
input[type="reset"].disabled:before,
input[type="reset"]:disabled:before,
input[type="button"].disabled:before,
input[type="button"]:disabled:before,
button.disabled:before,
button:disabled:before,
.button.disabled:before,
.button:disabled:before {
  color: rgba(160, 160, 160, 0.3) !important;
}


p {
  font-size: 16px;
}

#pageGroup {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10%;
}

.ivu-card:hover {
  transform: scale(1.01);
  box-shadow: 5px 5px 2px #d3dcda;
}
</style>
<template>
  <div id="app">
    <FixedNav active="/index.html"/>
    <div class="wrapper">
      <div class="sider">
        <SideBar/>
      </div>
      <div class="main">
        <MainArticles/>
      </div>
    </div>
    <Copyright/>
  </div>
</template>

<script>

import FixedNav from "@/components/FixedNav";
import MainArticles from "@/components/MainArticles";
import SideBar from "@/components/SideBar";
import Copyright from "@/components/Copyright";

export default {
  name: 'App',
  components: {
    MainArticles,
    FixedNav, SideBar, Copyright
  }
}
</script>

<style>
#app {
  background-color: #f4f4f4;
}

.wrapper {
  width: 90%;
  margin-top: 40px;
  margin-left: 5%;
  padding-bottom: 80px;
  display: -webkit-flex; /* Safari */
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.sider {
  display: flex;
  width: 25%;
  min-width: 300px;
  padding: 0 10px;
}

@media screen and (max-width: 680px){
  .sider {
    width: 100%;
  }
}

.main {
  display: flex;
  width: 70%;
  min-width: 380px;
  padding: 0 10px;
}

.ivu-layout {
  background-color: #f4f4f4 !important;
}

.ivu-layout-sider {
  background-color: #f4f4f4 !important;
}

</style>
